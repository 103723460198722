<template>
  <v-container class="pa-0 ma-0 fill-height text-left" fluid>
    <v-row justify="start">
      <v-col cols="12">
        <v-card
          class="d-flex fill-height fluid pa-0 ma-0"
          flat
          tile
          width="100%"
        >
          <v-scroll-x-transition appear>
            <v-img
              aspect-ratio="2"
              max-height="300"
              src="@/assets/images/doctors_header.jpg"
              transition="slide-x-transition"
              width="100%"
            >
              <v-container class="fill-height align-end bottom-gradient" fluid>
                <v-row align="center" justify="center">
                  <v-col>
                    <div class="white--text pb-10">
                      <span
                        class="pl-1 pl-md-8 text-h5 text-md-h4 text-uppercase font-weight-black"
                        style="text-shadow: 2px 2px 10px black"
                      >
                        Only the best specialists
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-img>
          </v-scroll-x-transition>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <div class="boxdivider"></div>
    </v-row>

    <v-row justify="center">
      <SearchBar :data="doctors" @search="updateFilteredData" />
    </v-row>

    <v-row align="center" justify="center">
      <div
        class="fill-height container--fluid mt-8 mb-8 mx-8"
        style="width: 100%"
      >
        <v-container class="fill-height" fluid>
          <v-row align="center" justify="start">
            <v-col
              v-for="(item, index) in filteredData"
              :key="index"
              class="d-flex child-flex"
              cols="12"
              sm="6"
              md="3"
            >
              <v-card hover class="rounded-lg" v-if="item.active">
                <div style="cursor: pointer" v-on:click="showProfile(item.id)">
                  <v-img
                    :lazy-src="require('@/assets/images/no-image.jpg')"
                    :src="
                      item.pictures[0] != null
                        ? item.pictures[0].url
                        : require('@/assets/images/no-image.jpg')
                    "
                    class="rounded-t-lg"
                    height="300"
                    position="top"
                    contain
                  >
                    <template v-slot:placeholder>
                      <v-row
                        align="center"
                        class="fill-height ma-0"
                        justify="center"
                      >
                        <v-progress-circular
                          color="grey lighten-5"
                          indeterminate
                        >
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </div>
                <v-btn
                  v-model="selectedId"
                  class="mx-0 mt-1"
                  elevation="0"
                  plain
                  text
                  @click="showProfile(item.id)"
                >
                  <span
                    class="pb-0 indigo--text text-subtitle-2 text-truncate"
                    v-text="item.name"
                  ></span>
                </v-btn>

                <div class="row pt-0 mt-0">
                  <div class="col-12">
                    <v-card-subtitle
                      class="pb-0 pt-0 text-subtitle-2 indigo--text text-capitalize text-truncate"
                    >
                      <router-link
                        :to="`/specialty-profile/${item.specialties[0]?.id}`"
                      >
                        <span v-if="item.specialties[0] != null">
                          {{
                            item.specialties[0].name_english
                              ? item.specialties[0].name_english
                              : item.specialties[0].name
                          }}
                        </span>
                        <span v-else> Not assigned </span>
                      </router-link>
                    </v-card-subtitle>
                  </div>
                </div>
                <v-card-actions class="mt-1 mb-1 justify-center">
                  <v-btn
                    :href="item.facebook_profile"
                    color="blue"
                    icon
                    target="_blank"
                  >
                    <v-icon color="primary" size="24px">mdi-facebook</v-icon>
                  </v-btn>
                  <v-btn
                    :href="item.instagram_profile"
                    color="blue"
                    icon
                    target="_blank"
                  >
                    <v-icon color="primary" size="24px">mdi-instagram</v-icon>
                  </v-btn>
                  <v-btn
                    :href="item.twitter_profile"
                    color="blue"
                    icon
                    target="_blank"
                  >
                    <v-icon color="primary" size="24px">mdi-twitter</v-icon>
                  </v-btn>
                  <v-btn
                    :href="item.youtube_video"
                    color="blue"
                    icon
                    target="_blank"
                  >
                    <v-icon color="primary" size="24px">mdi-youtube</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-row>
    <v-row justify="center" class="ma-10" v-if="doctors.length === 0">
      <div>{{ this.message }}</div>
    </v-row>
  </v-container>
</template>

<script>
import doctorsService from "@/providers/DoctorsService";
import SearchBar from "../searchBar/SearchBar.vue";

export default {
  name: "DoctorsComponent",

  components: {
    SearchBar,
  },

  data: () => ({
    selectedId: 0,
    doctors: [],
    filteredData: [],
    transparent: "rgba(255, 255, 255, 0)",
    message: "Loading...",
  }),
  methods: {

    isFechaVencida(fecha) {
      const fechaVencimiento = fecha.split("T")[0]; // Obtiene solo la parte de la fecha
      const fechaActual = new Date().toISOString().split("T")[0]; // Formatea la fecha actual en el mismo formato
      //console.log('Fecha Vencimiento:', fechaVencimiento);
      //console.log('Fecha Actual:', fechaActual);
      // Compara las fechas
      return fechaVencimiento > fechaActual;
    },

    getDoctors() {
      doctorsService.getViewListByNumber(true, true).then((record) => {
        this.doctors = record.value;
        this.filteredData = [...this.doctors];
        if (this.doctors.length === 0) {
          this.message = "There are no Doctors in the catalogue...";
        }
      });
    },

    updateFilteredData(filteredData) {
      this.filteredData = filteredData;
    },

    showProfile(idDoctor) {
      this.selectedId = idDoctor;
      this.$router.push({
        name: "DoctorProfile",
        params: {
          id: idDoctor,
        },
      });
    },
  },
  mounted() {
    this.doctors = [];
    this.filteredData = [];
    this.getDoctors();
  },
};
</script>

<style scoped>
.bottom-gradient {
  background-image: linear-gradient(
    to bottom,
    rgba(116, 148, 153, 0.29) 0%,
    rgba(15, 18, 21, 0.7) 100%
  );
  opacity: 1;
  background-color: transparent;
  background-blend-mode: normal;
}

#my-lax .v-parallax__content {
  background: linear-gradient(45deg, black, transparent);
}

.v-btn {
  text-transform: none !important;
}

.blend-opacity {
  opacity: 0.5;
  transition: opacity 0.4s ease-in-out;
  background-color: black;
  background-blend-mode: normal;
}

/* .blend-opacity:hover {
         opacity: 0.9;
         transition: opacity 0.4s ease-in-out;
         background-color: black;
         background-blend-mode: darken;
     }*/
.boxdivider {
  background-color: transparent;
  width: 100px;
  height: 50px;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom-width: 3px;
  border-bottom-color: blueviolet;
  border-bottom-style: solid;
  display: block;
}
</style>
